<template>
  <v-container>
    <v-row>
      <v-dialog
          v-if="dialog"
          v-model="dialog"
          max-width="700"
          overlay-opacity="0.7"
      >
        <v-card dark>

          <v-card-actions style="background-color: #cfcfcf" class="mb-2">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                small
                text
                @click="openFullImage(currentImage)">Otwórz w nowym oknie</v-btn>
            <v-btn
                right
                icon
                color="primary"
                text
                @click="dialog = false"
            >
              <v-icon right>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>

          <v-img
              :src="require('../../../assets/qms/tdm/' + currentImage)"
              cover
              max-height="600"
          ></v-img>
          <v-divider></v-divider>


        </v-card>
      </v-dialog>
      <v-col
          v-for="(image, index) in images"
          :key="index"
          class="d-flex child-flex"
          sm="12"
          md="2"
          xl="2"
          cols="12"

      >
        <v-img
            :src="require('../../../assets/qms/tdm/' + image.src)"
            height="200"
            aspect-ratio="1"
            class="grey lighten-2"
            @click.stop="showFullImage(image.src)"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

  methods: {
    showFullImage(path) {

      this.currentImage = path;
      this.dialog = true;
    },

    openFullImage(path) {
      let url = require('../../../assets/qms/tdm/' + path);
      window.open(url, '_blank');
    }
  },

  data() {
    return {
      dialog: false,
      currentImage: '',
      images: [
        {src: 'IMG_20190403_134159.jpg'},
        {src: 'IMG_20190403_134152.jpg'},
        {src: 'IMG_20190123_113457.jpg'},
        {src: 'IMG_20190123_113504.jpg'},
        {src: 'IMG_20190123_113358.jpg'},
        {src: '20180829_125331.jpg'},
        {src: 'IMG_20190603_144625.jpg'},
        {src: 'IMG_20190508_160532.jpg'},
        {src: 'IMG_20190508_141327.jpg'},
        {src: 'IMG_20220216_134229527.jpg'},
        {src: 'IMG_20211228_101014306.jpg'},
        {src: 'IMG_20211229_151632542.jpg'},
        {src: 'IMG_20200723_130329739.jpg'},
        {src: 'IMG_20210601_141506779_HDR.jpg'},
        {src: 'IMG_20220118_091851085_HDR.jpg'},
        {src: 'IMG_20211228_174817491.jpg'},
        {src: 'IMG_20210825_113947548.jpg'},
      ]
    }
  },

}
</script>
