<template>
  <v-sheet class="mt-8 ma-auto text-left" :width="sizeWidth">
    <v-sheet>
      <v-card>
        <v-card-title>{{ $t('software_capabilities') }} Siegla QMS</v-card-title>
        <v-card-text>
          <v-expansion-panels focusable>
            <v-expansion-panel v-for="(feature, index) in qmsFeatures" :key="index">
              <v-expansion-panel-header>
                {{ feature.label }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-sheet v-for="(item, index) in feature.features" :key="index" class="text-left pa-3 pl-0">
                  {{ item }}
                  <v-divider/>
                </v-sheet>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-sheet>

    <v-sheet class="my-4">
      <v-card>
        <v-card-title>
          {{$t('qms_integration')}}
        </v-card-title>
        <v-card-text>{{$t('qms_integration_desc')}}</v-card-text>
      </v-card>
    </v-sheet>

<!--    <v-sheet class="my-4">
      <v-card>
        <v-card-title>
          {{$t('qms_dedicated_extensions')}}
        </v-card-title>
        <v-card-text>{{$t('qms_dedicated_extensions_desc')}}</v-card-text>
      </v-card>
    </v-sheet>-->

  </v-sheet>
</template>
<script>

export default {

  data() {
    return {
      qmsFeatures: require('./qms-features.js').default.qmsFeatures,
      sizeWidth: 700 //TODO get from main configuration
    }
  },
}
</script>
