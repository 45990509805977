export default {
    qmsFeatures: [
        {
            name: 'tdm',
            label: "Obsługa klienta i funkcje automatu biletowego",
            features: [
                "Drukowanie biletów po wyborze usługi na ekranie dotykowym.",
                "Możliwość całkowitego lub częściowego odcinania wydruków.",
                "Powiadomienie e-mail o zbliżającym się wyczerpaniu papieru, wysyłane do podanych adresatów.",
                "Powiadomienie e-mail o całkowitym wyczerpaniu papieru, wysyłane do podanych adresatów.",
                "Informacja o wyczerpaniu papieru na ekranie automatu biletowego.",
                "Wyświetlanie informacji o stanie papieru w panelu użytkownika zarządzającego automatem biletowym.",
                "Tworzenie menu automatu biletowego z dowolną ilością pozycji.",
                "Tworzenie dowolnej liczby ekranów z przyciskami na automacie biletowym.",
                "Rozmieszczanie przycisków na automacie biletowym w kategoriach usług.",
                "Nielimitowany poziom zagnieżdżeń kategorii usług.",
                "Sterowanie kolejnością pozycji wyświetlanych na ekranie automatu biletowego.",
                "Wyznaczanie dowolnego symbolu, nazwy oraz opisu usługi wyświetlanej na ekranie.",
                "Dowolny wybór koloru przycisków, koloru tła oraz koloru i wielkości czcionki dla informacji wyświetlanych na ekranie automatu biletowego.",
                "Umieszczanie dowolnego logo na ekranie automatu biletowego.",
                "Zmiana języka interfejsu automatu biletowego poprzez wybór flagi na ekranie dotykowym.",
                "Automatyczny powrót ekranu biletowego do stanu początkowego po upływie wyznaczonego czasu.",
                "Wyświetlanie godziny oraz daty ze słownym zapisem dnia tygodnia na ekranie automatu biletowego.",
                "Automatyczne odcinanie nadmiaru papieru po wymianie rolki.",
                "Wyznaczanie harmonogramu pracy automatu biletowego wraz z komunikatem wyświetlanym poza jego godzinami.",
                "Wyznaczanie harmonogramu wyświetlania poszczególnych usług oraz kategorii usług.",
                "Możliwość ustalenia komunikatów wyświetlanych po wyborze usług, które pozostają widoczne w godzinach niemieszczących się w harmonogramie.",
                "Harmonogramowanie pracy biletomatu i wyświetlanych usług z podziałem na dni tygodnia.",
                "Ukrywanie wybranych usług poza wyznaczonymi godzinami lub wyświetlanie odpowiedniego komunikatu po ich wybraniu na ekranie automatu.",
                "Opcja automatycznego ukrywania usług, które nie są aktualnie świadczone przez żadne z otwartych stanowisk.",
                "Zliczanie osób oczekujących z podziałem na dział (grupę) usług.",
                "Wyświetlanie na ekranie automatu liczby osób oczekujących na realizację usługi w danym dziale.",
                "Wydruk na bilecie liczby osób oczekujących na realizację usługi w danym dziale.",
                "Wydruk dowolnej grafiki monochromatycznej na pobranym bilecie.",
                "Umieszczenie dowolnej informacji na bilecie.",
                "Wydruk różnej grafiki oraz dodatkowych informacji w zależności od wybranej usługi.",
                "Wydruk daty oraz godziny pobrania biletu na bilecie.",
                "Wybór rodzaju czcionki i jej wielkości dla poszczególnych tekstów drukowanych na bilecie.",
                "Edycja szablonów biletów z możliwością dowolnego pozycjonowania grafiki oraz tekstu.",
                "Podgląd szablonu biletu bez konieczności jego wydruku.",
                "Drukowanie biletów na papierze termicznym o szerokości 58/60/80/83mm oraz długości do 80m.",
                "Drukowanie biletów w rozdzielczości 8 punktów/mm, 203 dpi.",
                "Drukowanie biletów z szybkością 200mm/s.",
                "Możliwość regulacji długości pojedynczego biletu.",
                "Opcja wydruku biletu w wyznaczonej orientacji - obrót o 90, 180 lub 270 stopni.",
                "Możliwość przeprowadzenia wydruku testowego bez konieczności używania ekranu dotykowego i generowania miejsca w kolejce.",
                "Opcja wprowadzenia kodu rezerwacji i wydruk biletu uprawniającego do świadczenia usługi w podanym terminie.",
                "Zerowanie numerów biletów codziennie o godzinie 00:00.",
                "Opcja numerowania biletów bez względu na wybraną usługę - jeden wspólny licznik.",
                "Opcja numerowania biletów wg symbolu usługi - wiele wspólnych liczników.",
                "Opcja numerowania biletów wg usługi - tylko osobne liczniki.",
                "Limitowanie biletów wydawanych przez automat biletowy w poszczególne dni tygodnia.",
                "Możliwość zwiększania/zmniejszania limitów wydawanych biletów we wskazanym terminie.",
                "Możliwość zdefiniowania komunikatu wyświetlanego po wyborze usługi, której limit biletów został wyczerpany.",
                "Możliwość ukrycia usług, których limit biletów został wyczerpany.",
                "Wydruk biletu po przeskanowaniu kodu QR otrzymanego w procesie rezerwacji.",
                "Możliwość włączenia potrzeby wpisania kodu weryfikacyjnego podczas pobierania biletu.",
                "Możliwość zmiany funkcjonalności przycisku reprezentującego usługę na wybraną z listy dostępnych."
            ]
        },
        {
            name: 'queue_management',
            label: "Zarządzanie kolejką",
            features: [
                "Obsługa klientów i zarządzanie kolejką z użyciem terminala (przywoływacza stanowiskowego) wyposażonego w wyświetlacz LCD oraz ekran dotykowy.",
                "Możliwość obsługi klientów i zarządzania kolejką z dowolnego komputera w sieci lokalnej.",
                "Wzywanie kolejnych klientów pojedynczym przyciskiem na terminalu.",
                "Automatyczny wybór kolejnego klienta zgodnie z ustalonym scenariuszem obsługi.",
                "Tworzenie scenariuszy obsługi pozwalających na częstszą obsługę klientów oczekujących realizacji wybranych usług.",
                "Tworzenie scenariuszy obsługi pozwalających na automatyczne przesuwanie wybranej grupy klientów na początek kolejki.",
                "Przeglądanie stanu kolejki na terminalu stanowiskowym, włączając godzinę pobrania biletu przez każdego z klientów, symbol usługi, numer biletu i nazwę usługi.",
                "Wyświetlanie całkowitej liczby osób oczekujących na realizację usługi, która może być świadczona przez dane stanowisko.",
                "Możliwość wezwania dowolnego klienta z kolejki, bez względu na aktualny scenariusz obsługi.",
                "Wzywanie klienta przez wyświetlenie zapowiedzi wizualnej na dowolnej liczbie ekranów.",
                "Wzywanie klienta przez odtworzenie zapowiedzi słownej na dowolnej liczbie odtwarzaczy.",
                "Możliwość wykorzystania ekranu/telewizora z głośnikami jako odtwarzacza zapowiedzi słownych.",
                "Słowny odczyt liczebników w (numery biletów, pokojów, stanowisk itp.) w pełnej formie wyrazowej.",
                "Wyświetlanie na terminalu stanowiskowym informacji o poprawnym wyświetleniu zapowiedzi wizualnej wzywanego klienta.",
                "Wyświetlanie na terminalu stanowiskowym informacji o poprawnym odtworzeniu zapowiedzi słownej wzywanego klienta.",
                "Ponowienie zapowiedzi wizualnej oraz dźwiękowej ostatnio wezwanego klienta za pomocą terminala stanowiskowego.",
                "Zakończenie obsługi z automatycznym wezwaniem kolejnego klienta.",
                "Zakończenie obsługi bez automatycznego wzywania kolejnego klienta.",
                "Emisja zapowiedzi wizualnej i słownej z dowolnym numerem biletu i symbolem usługi.",
                "Obsługa petentów bez biletów poprzez utworzenie sprawy w terminalu stanowiskowym.",
                "Odtworzenie przez terminal stanowiskowy sygnału dźwiękowego po pojawieniu się pierwszego klienta.",
                "Możliwość regulacji głośności sygnału emitowanego przez terminal stanowiskowy.",
                "Zmiana orientacji ekranu przenośnych terminali stanowiskowych.",
                "Automatyczne przejście przenośnego terminala stanowiskowego w stan uśpienia po określonym czasie bez aktywności.",
                "Możliwość skierowania aktualnie obsługiwanego klienta z powrotem do kolejki oraz zmiany wybranej przez niego usługi bez konieczności ponownego pobierania biletu.",
                "Możliwość zmiany wybranej przez dowolnego klienta usługi bez konieczności ponownego pobierania biletu.",
                "Graficzne wyróżnienie biletu klienta, którego obsługa została przekierowana przez pracownika.",
                "Wyświetlanie listy odwiedzonych przez klienta stanowisk (listy zmian usług).",
                "Opcja wstrzymania obsługi aktualnie obsługiwanego klienta.",
                "Przeglądanie listy wszystkich klientów, których obsługa została wstrzymana (niezależnie od tego, które stanowisko dokonało wstrzymania).",
                "Wznawianie obsługi klientów, których obsługę wcześniej zawieszono.",
                "Możliwość przekierowywania klienta do innego stanowiska przez zawieszenie i wznowienie obsługi.",
                "Czasowa blokada terminala stanowiskowego między kolejnymi wezwaniami klientów.",
                "Autoryzacja pracownika przez podanie loginu i hasła w terminalu stanowiskowym.",
                "Możliwość autoryzacji pracownika przez podanie pojedynczego kodu PIN.",
                "Możliwość działania terminala stanowiskowego w sieci bezprzewodowej.",
                "Nadawanie i odbieranie uprawnień pracownikom do świadczenia poszczególnych usług.",
                "Opcja zezwolenia pracownikowi na samodzielne decydowanie o rodzaju świadczonych usług i obsługiwanych klientach.",
                "Wyłączenie na wybranych stanowiskach automatycznego wzywania klientów oczekujących realizacji wybranych usług.",
                "Opcja ograniczenia emisji wezwań z wybranych stanowisk tylko do wybranych wyświetlaczy przywoławczych.",
                "Możliwość umieszczenia podglądu stanu kolejki na dowolnej stronie internetowej."
            ]
        },
        {
            name: 'online_services',
            label: "Rezerwacje internetowe",
            features: [
                "Możliwość rezerwacji terminu (daty i godziny) świadczenia usługi przez sieć Internet.",
                "Rezerwowanie wizyt za pomocą formularza osadzanego na dowolnej stronie internetowej, niezależnie od wykorzystywanej przez serwer technologii.",
                "Możliwość pobrania dokumentu PDF zawierającego szczegóły dokonanej rezerwacji oraz kod QR dla automatów wyposażonych w kamerę.",
                "Wyświetlanie w formularzu rezerwacji kalendarza prezentującego terminy, w których można zarezerwować realizację wybranej usługi.",
                "Zabezpieczenie formularza rezerwacji przed nadużyciami poprzez konieczność przepisania jednorazowego kodu z wygenerowanej grafiki.",
                "Zakończenie procesu rezerwacji wyświetleniem kodu rezerwacji, terminu rezerwacji i nazwy realizowanej usługi.",
                "Wygenerowanie kodu QR zawierającego dane dotyczące rezerwacji.",
                "Realizacja rezerwacji przez wprowadzenie w automacie biletowym otrzymanego wcześniej kodu.",
                "Możliwość wyznaczenia dostępnych godzin rezerwacji dla poszczególnych usług w wybranych dniach tygodnia.",
                "Opcja określania liczby równoczesnych rezerwacji w tym samym terminie dla tej samej usługi.",
                "Grupowanie usług w sposób umożliwiający zablokowanie rezerwacji realizacji jednej usługi, przez rezerwację realizacji innej usługi świadczonej przez to samo stanowisko.",
                "Wykluczanie wybranych usług z mechanizmu rezerwacji.",
                "Tworzenie usług, których realizacja będzie możliwa jedynie po wcześniejszym dokonaniu rezerwacji.",
                "Opcja wyznaczania maksymalnego czasu spóźnienia klienta z rezerwacją realizacji usługi.",
                "Opcja wyznaczania minimalnego czasu poprzedzającego dokonanie rezerwacji.",
                "Opcja wyznaczania maksymalnej daty rezerwacji.",
                "Blokowanie możliwości dokonywania rezerwacji dla wybranych adresów IP.",
                "Możliwość dodatkowego oznaczenia zapowiedzi dotyczących klientów z rezerwacją.",
                "Panel umożliwiający dokonanie telefonicznej rezerwacji realizacji usługi.",
                "Panel pozwalający na przeglądanie wszystkich dokonanych rezerwacji w danym dniu, tygodniu, miesiącu.",
                "Podgląd terminów oraz kodów dla umówionych wizyt z poziomu panelu administracyjnego.",
                "Usuwanie rezerwacji wszystkich terminów starszych niż wyznaczona data.",
                "Możliwość wykluczania wybranych dni z systemu rezerwacji."
            ]
        },
        {
            name: 'administration',
            label: "Administracja i konfiguracja systemu",
            features: [
                "Zarządzanie systemem z poziomu komputera osobistego wyposażonego w system operacyjny i przeglądarkę internetową.",
                "Tworzenie nieograniczonej liczby kont administracyjnych i pracowniczych.",
                "Dostęp do panelu w języku angielskim.",
                "Dostęp do panelu w języku polskim.",
                "Podsumowanie aktualnego stanu stanowisk włączając identyfikator stanowiska, nazwę pracującego przy nim pracownika, średni czas obsługi pojedynczego klienta, łączny czas obsługi bieżącego dnia oraz dokładny czas ostatniego przywołania.",
                "Możliwość zdalnego zamknięcia dowolnego stanowiska.",
                "Możliwość zdalnego wylogowania dowolnego pracownika.",
                "Podgląd bieżącego stanu kolejki z wyszczególnieniem numerów biletów, nazw usług, godzin pobrania biletów oraz nazwisk pracowników aktualnie obsługujących klientów.",
                "Wyświetlanie liczby osób oczekujących w kolejce oraz osób aktualnie obsługiwanych.",
                "Podgląd bieżącego stanu kolejki z grupowaniem wg usług, wyszczególniając nazwę usługi i liczbę osób oczekujących na jej realizację.",
                "Podgląd statystyk dnia bieżącego z wyszczególnieniem nazwy usługi, całkowitego czasu przeznaczonego na jej realizację, średniego czasu przeznaczonego na jej realizację oraz średniego czasu oczekiwania w kolejce.",
                "Podgląd godzin pracy pracownika w dniu bieżącym z wyszczególnieniem dokładnego czasu otwarcia i zamknięcia stanowiska, wszystkich przerw oraz łącznego czasu pracy przy otwartym stanowisku.",
                "Resetowanie haseł pracowników z poziomu panelu managera pracowników.",
                "Zmiana hasła dostępu do własnego konta, niezależnie od poziomu uprawnień.",
                "Tworzenie, edycja, usuwanie, przeglądanie i wyszukiwanie usług prezentowanych na ekranie automatu biletowego.",
                "Tworzenie nieograniczonej liczby stanowisk pracowniczych przez managera pracowników.",
                "Ustalanie formatu zapowiedzi słownej z możliwością odczytu symbolu usługi, numeru biletu, identyfikatora stanowiska, nazwy stanowiska, numeru pokoju, numeru piętra.",
                "Ustalanie formatu zapowiedzi wizualnej z możliwością wyświetlenia symbolu usługi, numeru biletu, identyfikatora stanowiska, nazwy stanowiska, numeru pokoju, numeru piętra, oznaczenia sprawy z rezerwacją.",
                "Wyświetlanie na ekranach informacji o wzywanych i aktualnie obsługiwanych klientach zgodnie z wprowadzonym wcześniej formatem.",
                "Możliwość sortowania listy aktualnie obsługiwanych klientów wg numeru stanowiska lub od najnowszej zapowiedzi.",
                "Określanie czasu trwania wyróżnienia wzywanego biletu na ekranach informacyjnych.",
                "Możliwość przeprowadzenia testu odczytu zapowiedzi słownej z poziomu panelu, bez potrzeby generowania miejsca w kolejce.",
                "Usuwanie kolejki z systemu.",
                "Usuwanie archiwum zamkniętych spraw.",
                "Usuwanie rezerwacji.",
                "Zerowanie numerów biletów w dowolnym momencie.",
                "Skanowanie sieci lokalnej w poszukiwaniu urządzeń kompatybilnych z systemem kolejkowym SIEGLA QMS.",
                "Funkcja weryfikacji poprawności komunikacji między urządzeniami systemu.",
                "Możliwość zdalnego restartu automatu biletowego.",
                "Możliwość zdalnego restartu dowolnego ekranu informacyjnego.",
                "Możliwość zdalnego restartu odtwarzacza zapowiedzi.",
                "Możliwość generowania sztucznych danych testujących sposób wyświetlania informacji.",
                "Regulacja głośności zapowiedzi słownych.",
                "Dodawanie kolejnych urządzeń systemu kolejkowego z poziomu panelu administracyjnego.",
                "Przewodowa lub bezprzewodowa komunikacja między urządzeniami.",
                "Wybór barwy i materiału tworzącego obudowę automatu biletowego.",
                "Możliwość zabudowy urządzeń włóknem szklanym.",
                "Gotowość systemu do przyłączenia do istniejącej infrastruktury sieciowej.",
                "Ekrany dotykowe wykonane w technologii pojemnościowej, IR lub SAW.",
                "Zabezpieczenie oprogramowania przed skutkami zaników napięcia.",
                "Zdolność systemu do przywrócenia stanu po przerwie w dostawie zasilania.",
                "Tworzenie szablonów wielokrotnego użytku dla ekranów informacyjnych.",
                "Możliwość konfiguracji barw tła, barw czcionek oraz ich wielkości w szablonach ekranów informacyjnych.",
                "Wyświetlanie i formatowanie paska informacyjnego z dowolną wiadomością na wyświetlaczach LCD.",
                "Obsługa wyświetlaczy (matryc) LED jako wyświetlaczy stanowiskowych.",
                "Blokowanie dostępu do panelu ze wskazanych adresów IP.",
                "Tworzenie kopii zapasowej całego systemu lub wskazanych elementów.",
                "Eksport/import utworzonej kopii zapasowej.",
                "Przeglądanie dziennika zdarzeń systemowych z podziałem na wpisy informacyjne oraz ostrzeżenia.",
                "Przeglądanie danych statystycznych związanych z wydajnością pracowników we wskazanych dniach.",
                "Przeglądanie danych statystycznych zawierających informacje o popularności usług oraz czasem oczekiwania i czasem realizacji usługi.",
                "Przeglądanie danych statystycznych związanych z ilością pobranych biletów dla każdej z usług.",
                "Przeglądanie historii zrealizowanych spraw.",
                "Eksport danych statystycznych do formatów: XLS, CSV, PDF, HTML.",
                "Możliwość formatowania eksportowanych danych wg określonych kryteriów.",
                "Harmonogram włączania i wyłączania ekranów automatów biletowych oraz wyświetlaczy."
            ]
        }
    ]
}
